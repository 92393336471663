/*
//var SERVER_HOST = "http://localhost";
var SERVER_HOST = "http://www.futurefactory.at";
//var SERVER_HOST = "http://www.pyrotrade.futurefactory.at";

// read config file and set port
var initialization = {};
var rawFile = new XMLHttpRequest();
rawFile.open("GET", "../../config.json", false);
rawFile.onreadystatechange = function ()
{
	if(rawFile.readyState === 4)
	{
		if(rawFile.status === 200 || rawFile.status == 0)
		{
      alert("found")
			var allText = rawFile.responseText;
			initialization = JSON.parse(allText);
			window.SERVER_HOST += ":" + initialization.port + "/";
		}
		else
		{
      alert("not found")
			window.SERVER_HOST += ":3000/";
		}
	}
}
rawFile.send(null);
*/

//log("initialization: ", initialization);

var TIME_NOT_VALID_MS = -2208988800000;

function testCall() {
  alert("test call");
}

// marks some entry of the local db as deleted
function markModelDeletedByIdAtServer(
  $rootScope,
  objectFromServer,
  nameDataModel,
  doneCallback
) {
  var nameTable = dataModels[nameDataModel].table;

  var table = $rootScope.db.getSchema().table(nameTable);

  $rootScope.db
    .update(table)
    .set(table.deleted, true)
    .where(table.idAtServer.eq(objectFromServer.idAtServer))
    .exec()
    .then(function () {
      if (doneCallback) doneCallback();
    });
}

// updates an existing entry in the local db
function updateEditedModelByIdAtServer(
  $rootScope,
  object,
  nameDataModel,
  functionOnSuccess
) {
  var nameTable = dataModels[nameDataModel].table;

  var table = $rootScope.db.getSchema().table(nameTable);

  var updQuery = $rootScope.db.update(table);

  var tableDef = dataModels[nameDataModel];

  log("updateEditedModelByIdAtServer: " + nameTable);
  log("updateEditedModelByIdAtServer: ", object);

  tableDef.columns.forEach(function (column) {
    updQuery.set(table[column.nameLocal], object[column.nameScope]);
  });

  updQuery
    .where(table.idAtServer.eq(object.idAtServer))
    .exec()
    .then(function (results) {
      $rootScope.db
        .select()
        .from(table)
        .where(table.idAtServer.eq(object.idAtServer))
        .exec()
        .then(function (results) {
          results.forEach(function (row) {
            object.idAtClient = row["idAtClient"];

            if (functionOnSuccess) functionOnSuccess();

            return;
          });
        });
    });
}

// stores or updates an object from the server locally
function saveOrUpdateItemFromServer(
  $rootScope,
  itemFromServer,
  nameDataModel,
  functionOnSuccess
) {
  log("nameDataModel: " + nameDataModel);
  var nameTable = dataModels[nameDataModel].table;

  var table = $rootScope.db.getSchema().table(nameTable);

  stopTime(
    "saveOrUpdate " + nameDataModel + " - " + itemFromServer.idAtServer,
    "start"
  );

  $rootScope.db
    .select(table.idAtServer)
    .from(table)
    .where(table.idAtServer.eq(itemFromServer.idAtServer))
    .exec()
    .then(function (results) {
      stopTime(
        "saveOrUpdate " + nameDataModel + " - " + itemFromServer.idAtServer,
        "mid " + nameDataModel
      );

      if (results.length == 0) {
        // create new entry in local db
        saveNewObjectLocally(
          $rootScope,
          itemFromServer,
          nameDataModel,
          function () {
            log(
              "stored new object: " +
                itemFromServer.idAtClient +
                "/" +
                itemFromServer.idAtServer +
                " - " +
                nameDataModel
            );
            stopTime(
              "saveOrUpdate " +
                nameDataModel +
                " - " +
                itemFromServer.idAtServer,
              "end 1 " + nameDataModel
            );
            if (functionOnSuccess) functionOnSuccess();
          }
        );
      } else {
        // update existing entry in local db
        updateEditedModelByIdAtServer(
          $rootScope,
          itemFromServer,
          nameDataModel,
          function () {
            log(
              "updated object: " +
                itemFromServer.idAtClient +
                "/" +
                itemFromServer.idAtClient +
                " - " +
                nameDataModel
            );
            stopTime(
              "saveOrUpdate " +
                nameDataModel +
                " - " +
                itemFromServer.idAtServer,
              "end 2 " + nameDataModel
            );
            if (functionOnSuccess) functionOnSuccess();
          }
        );
      }
    });
}

var mapTimes = new Map();

function stopTime(name, text) {
  var time = mapTimes.get(name);

  if (!time) {
    time = new Date();
    mapTimes.set(name, time);
  } else {
    var time2 = new Date();
    var durationMS = time2.getTime() - time.getTime();
    if (!name) name = "";
    log("duration (" + name + ") " + text + ": " + durationMS / 1000);

    mapTimes.set(name, time2);
  }
}

function getObjectByIdAtClient(objects, idAtClient) {
  var objectFound = null;
  if (objects) {
    objects.some(function (object) {
      if (object.idAtClient == idAtClient) {
        objectFound = object;
        return;
      }
    });
  }
  return objectFound;
}

function getObjectByIdAtServer(objects, idAtServer) {
  var objectFound = null;
  if (objects) {
    for (var iO = 0; iO < objects.length; iO++) {
      if (objects[iO].idAtServer == idAtServer) {
        objectFound = objects[iO];
        break;
      }
    }
    /*
		objects.some (function(object){
			if(object.idAtServer==idAtServer) {
				objectFound=object;
				return;
			}
		});
		*/
  }
  return objectFound;
}

function getIdsClientFromIdsServer(objects, idsAtServerArr) {
  var idsAtClientArr = [];
  for (var iS = 0; iS < idsAtServerArr.length; iS++) {
    var object = null;
    object = getObjectByIdAtServer(objects, idsAtServerArr[iS]);
    if (object && !idsAtClientArr.includes(object.idAtClient)) {
      idsAtClientArr.push(object.idAtClient);
    }
  }

  return idsAtClientArr;
}

function getIdsServerFromIdsClient(objects, idsAtClientArr) {
  var idsAtServerArr = [];
  for (var iC = 0; iC < idsAtClientArr.length; iC++) {
    var object = getObjectByIdAtClient(objects, idsAtClientArr[iC]);
    if (object && !idsAtServerArr.includes(object.idAtServer)) {
      idsAtServerArr.push(object.idAtServer);
    }
  }

  return idsAtServerArr;
}

/* probably dead code
function saveOrUpdateEntryFromServer(entryFromServer, nameDataModel){
	var nameTable = dataModels[nameDataModel].table;

	var table = db.getSchema().table(nameTable);

	db.select().from(table).
		where(table.idAtServer.eq(entryFromServer.idAtServer)).exec().
		then(function(results) {

		if(results.length == 0) {
			// fetch idCustomerAtClient
			var tableCustomers = db.getSchema().table(dataModels.Kunde.table);
			db.select().from(tableCustomers).
			where(tableCustomers.idAtServer.eq(entryFromServer.idCustomerAtServer)).
			exec().then(function (results){
				if(results.length == 1){
					entryFromServer.idCustomerAtClient = results[0]['idAtClient'];
					saveNewEntry(nameDataModel, entryFromServer.idCustomerAtClient, entryFromServer);
					log("found customer with idAtServer: " +
						entryFromServer.idCustomerAtServer);
				} else {
					log("customer not found with idAtServer: " +
						entryFromServer.idCustomerAtServer);
				}
			});
		}else{
			log("updating entry " + nameTable);
			$scope.updateEditedModelByIdAtServer($rootScope, entryFromServer, nameDataModel);
		}
	});
}
*/

function saveNewObjectLocally(
  $rootScope,
  object,
  nameDataModel,
  functionOnSuccess
) {
  var nameTable = dataModels[nameDataModel].table;

  log("nameTable: " + nameTable + ", nameDataModel: " + nameDataModel);
  var table = $rootScope.db.getSchema().table(nameTable);
  var tableDef = dataModels[nameDataModel];

  var rowObj = {
    idAtServer: object.idAtServer,
    deleted: 0,
  };

  tableDef.columns.forEach(function (column) {
    rowObj[column.nameLocal] = object[column.nameScope];
    log(
      "setting column: " +
        column.nameLocal +
        " <- " +
        column.nameScope +
        ": " +
        object[column.nameScope]
    );
  });

  log("saving new object: " + nameTable + " " + JSON.stringify(rowObj));

  var row = table.createRow(rowObj);

  stopTime("insert into db", "start");
  log("insert into db", rowObj);
  var res = $rootScope.db
    .insert()
    .into(table)
    .values([row])
    .exec()
    .then(function (rows, err) {
      stopTime("insert into db", "end");
      log("got id: " + rows[0]["idAtClient"]);
      object.idAtClient = rows[0]["idAtClient"];
      log("saved new object - idAtClient: " + object.idAtClient);
      if (functionOnSuccess) functionOnSuccess(object.idAtClient);
    });
}

// store an 'update time' operation to be executed at the server
function saveNewModelManipulationOfTime(
  $rootScope,
  nameTable,
  idAtClient,
  nameColumn,
  strTime
) {
  log(
    "saving object manipulation time: nameTable=" +
      nameTable +
      ", strTime=" +
      strTime
  );

  var tableManipulations = $rootScope.db.getSchema().table("manipulations");

  var rows = [];

  // the 'update time' operation
  var row = tableManipulations.createRow({
    id: 0,
    type: "updateTimeIfGreater",
    nameTable: nameTable,
    idAtClient: idAtClient,
    nameColumn: nameColumn,
    time: strTime,
  });
  rows.push(row);

  // store in table 'manipulations'
  log("# inserting rows: " + rows.length);
  $rootScope.db
    .insertOrReplace()
    .into(tableManipulations)
    .values(rows)
    .exec()
    .then(function (results) {
      log("", results);
    });
}

// store an 'increment value' operation to be executed at the server
function saveNewModelManipulationIncrement(
  $rootScope,
  nameTable,
  idAtClient,
  nameColumn
) {
  log("saving object manipulation increment: nameTable=" + nameTable);

  var tableManipulations = $rootScope.db.getSchema().table("manipulations");

  var rows = [];

  // the 'increment value' operation
  var row = tableManipulations.createRow({
    id: 0,
    type: "incrementColumn",
    nameTable: nameTable,
    idAtClient: idAtClient,
    nameColumn: nameColumn,
  });
  rows.push(row);

  // store in table 'manipulations'
  log("# inserting rows: " + rows.length);
  $rootScope.db
    .insertOrReplace()
    .into(tableManipulations)
    .values(rows)
    .exec()
    .then(function (results) {
      log("", results);
    });
}

// store 'create entry' operations to be executed at the server
function saveNewModelManipulation($rootScope, object, nameModel, callbackDone) {
  var nameTable = dataModels[nameModel].table;

  log("saving object manipulation create: nameTable=" + nameTable);
  log("saving object manipulation create: ", object);

  //
  var tableManipulations = $rootScope.db.getSchema().table("manipulations");

  var rows = [];

  // the create operation
  var row = tableManipulations.createRow({
    id: 0,
    type: "create",
    nameTable: nameTable,
    idAtClient: object.idAtClient,
    nameColumn: "",
    time: "1970-01-01 00:00:00",
  });
  rows.push(row);

  // update operations column by column
  var tableDef = dataModels[nameModel];
  tableDef.columns.forEach(function (column) {
    log(
      "adding manipulation row of creation: " +
        object.idAtClient +
        " " +
        column.nameLocal
    );

    var row = tableManipulations.createRow({
      id: 0,
      type: "update",
      nameTable: nameTable,
      idAtClient: object.idAtClient,
      nameColumn: column.nameLocal,
      time: "1970-01-01 00:00:00",
    });
    rows.push(row);
  });

  // store in table 'manipulations'
  log("# inserting rows: " + rows.length);
  if (rows.length > 0) {
    $rootScope.db
      .insertOrReplace()
      .into(tableManipulations)
      .values(rows)
      .exec()
      .then(function (results) {
        if (callbackDone) callbackDone();
      });
  }
}

// update an object locally
function updateEditedModelByIdAtClient(
  $rootScope,
  object,
  nameDataModel,
  callbackDone
) {
  var nameTable = dataModels[nameDataModel].table;
  log(
    "updateEditedModelByIdAtClient nameTable: " +
      nameTable +
      ", nameDataModel: " +
      nameDataModel +
      ", object: ",
    object
  );

  var table = $rootScope.db.getSchema().table(nameTable);

  var updQuery = $rootScope.db.update(table);
  var tableDef = dataModels[nameDataModel];
  tableDef.columns.forEach(function (column) {
    var value = object[column.nameScope];
    if (typeof value === "undefined") value = null;
    log(
      "setting " + column.nameLocal + " - " + column.nameScope + " to " + value
    );
    updQuery.set(table[column.nameLocal], value);
  });

  log("updating " + nameTable + " ", object);

  try {
    updQuery
      .where(table.idAtClient.eq(object.idAtClient))
      .exec()
      .then(function () {
        log("updated " + nameTable + " ", object);
        if (callbackDone) return callbackDone();
      });
  } catch (err) {
    log("caught err: ", err);
  }
}

// store update operations to be executed at the server
function saveEditedModelManipulation(
  $rootScope,
  objectPrev,
  objectNew,
  nameDataModel
) {
  var nameTable = dataModels[nameDataModel].table;

  //
  var tableManipulations = $rootScope.db.getSchema().table("manipulations");

  var rows = [];

  var tableDef = dataModels[nameDataModel];
  tableDef.columns.forEach(function (column) {
    log(
      "nameTable: " +
        nameTable +
        ", nameDataModel: " +
        nameDataModel +
        ", column: ",
      column
    );
    log("columnPrev: ", objectPrev[column.nameScope]);
    log("columnNew:  ", objectNew[column.nameScope]);
    log(
      nameTable +
        " - " +
        column.nameLocal +
        " prev/new: " +
        objectPrev[column.nameScope] +
        " " +
        objectNew[column.nameScope]
    );

    // has the value changed?
    var possibleDatePrev = new Date(objectPrev[column.nameScope]);
    var possibleDateNew = new Date(objectNew[column.nameScope]);

    var valueChanged = false;
    //if(!isNaN(possibleDatePrev) && !isNaN(possibleDateNew)) {
    //	valueChanged = (possibleDatePrev.getTime() != possibleDateNew.getTime());
    //} else {
    valueChanged = objectPrev[column.nameScope] != objectNew[column.nameScope];
    //}
    log("value changed: " + valueChanged);

    if (valueChanged) {
      log(
        "adding manipulation row of editing: " +
          objectPrev.idAtClient +
          " " +
          column.nameLocal
      );

      if (objectPrev.idAtClient) {
        // assemble the operation to be done on server
        var manipulation = {};
        manipulation.type = "update";
        manipulation.nameTable = nameTable;
        manipulation.idAtClient = objectPrev.idAtClient;
        manipulation.nameColumn = column.nameLocal;

        // delete previous update operation of the same type;
        // repetition of same manipulation is not necessary
        $rootScope.db
          .delete()
          .from(tableManipulations)
          .where(
            lf.op.and(
              tableManipulations.type.eq("update"),
              lf.op.and(
                tableManipulations.nameTable.eq(manipulation.nameTable),
                lf.op.and(
                  tableManipulations.idAtClient.eq(manipulation.idAtClient),
                  tableManipulations.nameColumn.eq(manipulation.nameColumn)
                )
              )
            )
          )
          .exec()
          .then(function () {});

        // an update operation
        var row = tableManipulations.createRow({
          id: 0,
          type: manipulation.type,
          nameTable: manipulation.nameTable,
          idAtClient: manipulation.idAtClient,
          nameColumn: manipulation.nameColumn,
        });
        rows.push(row);
      }
    }
  });

  if (rows.length > 0) {
    log("# inserting rows: " + rows.length);

    // store operations in table 'manipulations'
    $rootScope.db
      .insertOrReplace()
      .into(tableManipulations)
      .values(rows)
      .exec();
  }
}

// load entries of some type for a customer from the local db
function loadEntries(
  nameModel,
  idCustomerAtClient,
  orderBy,
  $rootScope,
  $scope,
  functionOnSuccess
) {
  var nameTable = dataModels[nameModel].table;
  var table = $rootScope.db.getSchema().table(nameTable);

  log(
    "loading " + nameTable + " of idCustomerAtClient " + idCustomerAtClient,
    " orderby: " + orderBy
  );

  var tableDef = dataModels[nameModel];

  $scope.entries = [];
  if (!orderBy) orderBy = "idAtClient";

  var query;
  if (!isNaN(idCustomerAtClient) && idCustomerAtClient > 0)
    query = $rootScope.db
      .select()
      .from(table)
      .where(table.idCustomerAtClient.eq(idCustomerAtClient));
  else query = $rootScope.db.select().from(table);

  query.exec().then(function (results) {
    results.forEach(function (row) {
      var entry = {};
      entry.idAtClient = row["idAtClient"];
      entry.idAtServer = row["idAtServer"];
      entry.deleted = row["deleted"];

      entry.idCustomerAtClient = row["idCustomerAtClient"];

      tableDef.columns.forEach(function (column) {
        entry[column.nameScope] = row[column.nameLocal];
      });

      log("found an " + nameTable + ": ", entry);

      if (!entry.deleted) {
        $scope.entries.push(entry);
        $scope.$apply();
      }
    });

    $scope.entries.sort(function (a, b) {
      return a[orderBy] > b[orderBy] ? 1 : -1;
    });

    if (functionOnSuccess != null) functionOnSuccess($scope.entries);
  });
}

// mark an local entry as deleted
function deleteEntry($rootScope, nameDataModel, idAtClient) {
  var nameTable = dataModels[nameDataModel].table;

  var table = $rootScope.db.getSchema().table(nameTable);

  log("deleting " + nameTable + ": " + idAtClient);

  $rootScope.db
    .update(table)
    .set(table.deleted, true)
    .where(table.idAtClient.eq(idAtClient))
    .exec();
}

// load a database entry locally
function loadEntry($rootScope, nameDataModel, idAtClient, functionOnSuccess) {
  log("load single " + nameDataModel + ": " + idAtClient);

  var table = $rootScope.db.getSchema().table(dataModels[nameDataModel].table);
  var tableDef = dataModels[nameDataModel];
  log("   nameDataModel is " + nameDataModel);
  log("   tableDef is ", tableDef);

  $rootScope.db
    .select()
    .from(table)
    .where(table.idAtClient.eq(idAtClient))
    .exec()
    .then(function (results) {
      results.forEach(function (row) {
        var entry = {};
        entry.idAtClient = row["idAtClient"];
        entry.idAtServer = row["idAtServer"];
        entry.deleted = row["deleted"];

        entry.idCustomerAtClient = row["idCustomerAtClient"];

        tableDef.columns.forEach(function (column) {
          entry[column.nameScope] = row[column.nameLocal];
        });

        log("   tableDef is ", tableDef);
        log("   found an " + nameDataModel + ": ", entry);

        if (functionOnSuccess != null) functionOnSuccess(entry);

        return;
      });
    });
}

// store operation 'delete' to be executed at the server
function deleteModelManipulations(
  $rootScope,
  nameModel,
  idAtClient,
  callbackDone
) {
  var nameTable = dataModels[nameModel].table;

  var tableManipulations = $rootScope.db.getSchema().table("manipulations");

  var rows = [];

  // the operation
  var row = tableManipulations.createRow({
    id: 0,
    type: "delete",
    nameTable: nameTable,
    idAtClient: idAtClient,
    nameColumn: "",
  });
  rows.push(row);

  // store operation in table manipulation
  $rootScope.db
    .insertOrReplace()
    .into(tableManipulations)
    .values(rows)
    .exec()
    .then(function () {
      if (callbackDone) callbackDone();
    });
}

// formats all values (member names end with 'V') to a double decimal number with the currency and stores it as a new member
function setPrices(object, currency) {
  for (var propertyName in object) {
    if (propertyName.endsWith("V")) {
      log("set price of property: " + propertyName);
      var propertyNamePrice = propertyName.substring(
        0,
        propertyName.length - 1
      );
      object[propertyNamePrice] = convertValueToPrice(
        object[propertyName],
        currency
      );
    }
  }
}

/* probably dead code
function pausecomp(millis)
 {
  var date = new Date();
  var curDate = null;
  do { curDate = new Date(); }
  while(curDate-date < millis);
}
*/

// strips rtf format data from a rtf string
function convertRtfToPlain(rtf) {
  if (!rtf) return "";
  rtf = rtf.replace(/\\par[d]?/g, "");
  return rtf
    .replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, "")
    .trim();
}

// formats a value to a double decimal number with the currency added
function convertValueToPrice(value, currency) {
  if (!value) return "0,00 " + currency;
  else {
    var price = (Math.round(value * 100.0) / 100.0).toLocaleString("de-DE");
    if (price.charAt(price.length - 3) == ",");
    else if (price.charAt(price.length - 2) == ",") price = price + "0";
    else price = price + ",00";

    return price + " " + currency;
  }
}

// multiplies a value-currency tuple by the factor mult
function valueAndCurrencyMult(valueAndCurrency, mult) {
  var valueAndCurrencyArr = valueAndCurrency.split(" ");

  while (
    valueAndCurrencyArr[0].indexOf(".") >= 0 &&
    valueAndCurrencyArr[0].indexOf(",") >= 0
  )
    valueAndCurrencyArr[0] = valueAndCurrencyArr[0].replace(".", "");

  log("value is " + valueAndCurrencyArr[0]);

  var value = parseFloat(valueAndCurrencyArr[0].replace(",", "."));
  return convertValueToPrice(value * mult, valueAndCurrencyArr[1]);
}

function dateToMySQLString(date) {
  return (
    date.getFullYear() +
    "-" +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("00" + date.getDate()).slice(-2) +
    " " +
    ("00" + date.getHours()).slice(-2) +
    ":" +
    ("00" + date.getMinutes()).slice(-2) +
    ":" +
    ("00" + date.getSeconds()).slice(-2)
  );
}

function formatDateTime(date) {
  if (date && date.getTime() > 0)
    return formatDate(date) + " " + formatTime(date);
  else return "";
}

function formatTime(date) {
  return (
    (date.getHours() < 10 ? "0" : "") +
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? "0" : "") +
    date.getMinutes()
  );
}

function formatDate(date) {
  if (date && date.getTime() > 0)
    return (
      (date.getDate() < 10 ? "0" : "") +
      date.getDate() +
      "." +
      (date.getMonth() + 1 < 10 ? "0" : "") +
      (date.getMonth() + 1) +
      "." +
      date.getFullYear()
    );
  else return "";
}

function formatDate_(date) {
  if (date && date.getTime() > 0)
    return (
      date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
    );
  else return "";
}

function formatTime_(date) {
  return (
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? "0" : "") +
    date.getMinutes()
  );
}

function formatDateTime_(date) {
  if (date && date.getTime() > 0)
    return formatDate_(date) + " " + formatTime_(date);
  else return "";
}

// load available payment types from local db
function loadPaymentTypes($rootScope, $scope, callbackDone) {
  var tablePaymentTypes = $rootScope.db
    .getSchema()
    .table(dataModels.Zahlungsart.table);

  $scope.paymentTypes = [];

  $rootScope.db
    .select()
    .from(tablePaymentTypes)
    .where(tablePaymentTypes.active.eq(1))
    .orderBy(tablePaymentTypes.idAtServer, lf.Order.ASC)
    .exec()
    .then(function (results) {
      results.forEach(function (row) {
        var paymentType = {};
        paymentType.idAtClient = row["idAtClient"];
        paymentType.idAtServer = row["idAtServer"];
        paymentType.deleted = row["deleted"];

        tableDef = dataModels["Zahlungsart"];
        tableDef.columns.forEach(function (column) {
          paymentType[column.nameScope] = row[column.nameLocal];
        });

        if (!paymentType.deleted) {
          $scope.paymentTypes.push(paymentType);
        }
      });

      if (callbackDone) callbackDone($scope.paymentTypes);
    });
}

// load available payment targets from local db
function loadPaymentTargets($rootScope, $scope, callbackDone) {
  var tablePaymentTargets = $rootScope.db
    .getSchema()
    .table(dataModels.Zahlungsziel.table);

  $scope.paymentTargets = [];

  $rootScope.db
    .select()
    .from(tablePaymentTargets)
    .where(tablePaymentTargets.active.eq(1))
    .orderBy(tablePaymentTargets.idAtServer, lf.Order.ASC)
    .exec()
    .then(function (results) {
      results.forEach(function (row) {
        var paymentTarget = {};
        paymentTarget.idAtClient = row["idAtClient"];
        paymentTarget.idAtServer = row["idAtServer"];
        paymentTarget.deleted = row["deleted"];

        tableDef = dataModels["Zahlungsziel"];
        tableDef.columns.forEach(function (column) {
          paymentTarget[column.nameScope] = row[column.nameLocal];
        });

        if (!paymentTarget.deleted) {
          $scope.paymentTargets.push(paymentTarget);
        }
      });

      if (callbackDone) callbackDone($scope.paymentTargets);
    });
}

// load available pricelists from local db
function loadPricelists($rootScope, $scope, callbackDone) {
  var tablePrices = $rootScope.db
    .getSchema()
    .table(dataModels.Preisliste.table);

  $scope.pricelists = [];

  $rootScope.db
    .select()
    .from(tablePrices)
    .where(tablePrices.active.eq(1))
    .orderBy(tablePrices.idAtServer, lf.Order.ASC)
    .exec()
    .then(function (results) {
      results.forEach(function (row) {
        var pricelist = {};
        pricelist.idAtClient = row["idAtClient"];
        pricelist.idAtServer = row["idAtServer"];
        pricelist.deleted = row["deleted"];

        tableDef = dataModels["Preisliste"];
        tableDef.columns.forEach(function (column) {
          pricelist[column.nameScope] = row[column.nameLocal];
        });

        if (!pricelist.deleted) {
          $scope.pricelists.push(pricelist);
        }
      });

      if (callbackDone) callbackDone($scope.pricelists);
    });
}

function getModelColumnDefinitionByNameLocal(nameTableModel, nameColumnLocal) {
  var tableDefinition = dataModels[nameTableModel];
  log("tableDefinition for " + nameTableModel + ": ", tableDefinition);
  if (!tableDefinition) return undefined;

  log("nameColumnLocal: " + nameColumnLocal);

  for (var iColumn = 0; iColumn < tableDefinition.columns.length; iColumn++) {
    var columnDefinition = tableDefinition.columns[iColumn];
    if (columnDefinition.nameLocal == nameColumnLocal) {
      log("found columnDefinition", columnDefinition);
      return columnDefinition;
    }
  }

  return undefined;
}

// load available states of orders from local db
function loadStatesOrder($rootScope, $scope, functionOnSuccess) {
  var tableStatesOrders = $rootScope.db
    .getSchema()
    .table(dataModels.Auftragsstatus.table);

  $scope.statesOrder = [];

  $rootScope.db
    .select()
    .from(tableStatesOrders)
    .exec()
    .then(function (results) {
      results.forEach(function (row) {
        var stateOrder = {};
        stateOrder.idAtClient = row["idAtClient"];
        stateOrder.idAtServer = row["idAtServer"];
        stateOrder.deleted = row["deleted"];

        tableDef = dataModels["Auftragsstatus"];
        tableDef.columns.forEach(function (column) {
          stateOrder[column.nameScope] = row[column.nameLocal];
        });

        if (!stateOrder.deleted) {
          $scope.statesOrder.push(stateOrder);
        }
      });

      if (functionOnSuccess != null) functionOnSuccess($scope.statesOrder);
    });
}

// load available task categories from local db
function loadTaskCategories($rootScope, $scope, functionOnSuccess) {
  var tableTaskCategories = $rootScope.db
    .getSchema()
    .table(dataModels.Aufgabenkategorie.table);

  $scope.taskCategories = [];

  $rootScope.db
    .select()
    .from(tableTaskCategories)
    .exec()
    .then(function (results) {
      results.forEach(function (row) {
        var taskCategory = {};
        taskCategory.idAtClient = row["idAtClient"];
        taskCategory.idAtServer = row["idAtServer"];
        taskCategory.deleted = row["deleted"];

        tableDef = dataModels["Aufgabenkategorie"];
        tableDef.columns.forEach(function (column) {
          taskCategory[column.nameScope] = row[column.nameLocal];
        });

        if (!taskCategory.deleted) {
          $scope.taskCategories.push(taskCategory);
        }
      });

      log("task categories: ", $scope.taskCategories);
      if (functionOnSuccess != null) functionOnSuccess($scope.taskCategories);
    });
}

// loads the available sexes from the local db
function loadSexes($rootScope, $scope, functionOnSuccess) {
  var tableSexes = $rootScope.db.getSchema().table(dataModels.Geschlecht.table);

  $scope.sexes = [];

  $rootScope.db
    .select()
    .from(tableSexes)
    .exec()
    .then(function (results) {
      results.forEach(function (row) {
        var sex = {};
        sex.idAtClient = row["idAtClient"];
        sex.idAtServer = row["idAtServer"];
        sex.deleted = row["deleted"];

        tableDef = dataModels["Geschlecht"];
        tableDef.columns.forEach(function (column) {
          sex[column.nameScope] = row[column.nameLocal];
        });

        if (!sex.deleted) {
          $scope.sexes.push(sex);
        }
      });

      log("sexes: ", $scope.sexes);
      if (functionOnSuccess != null) functionOnSuccess($scope.sexes);
    });
}

// loads the available countries from the local db
function loadCountries($rootScope, $scope, functionOnSuccess) {
  var table = $rootScope.db.getSchema().table(dataModels.Land.table);

  $scope.countries = [];

  $rootScope.db
    .select()
    .from(table)
    .exec()
    .then(function (results) {
      results.forEach(function (row) {
        var country = {};
        country.idAtClient = row["idAtClient"];
        country.idAtServer = row["idAtServer"];
        country.deleted = row["deleted"];

        tableDef = dataModels["Land"];
        log("tabledef", tableDef);
        tableDef.columns.forEach(function (column) {
          country[column.nameScope] = row[column.nameLocal];
        });

        if (!country.deleted) {
          $scope.countries.push(country);
        }
      });

      log("countries: ", $scope.countries);
      if (functionOnSuccess != null) functionOnSuccess($scope.countries);
    });
}

// loads the available customer categories from the local db
function loadCustomerCategories($rootScope, $scope, functionOnSuccess) {
  var table = $rootScope.db.getSchema().table(dataModels.Kundenkategorie.table);

  $scope.customerCategories = [];

  $rootScope.db
    .select()
    .from(table)
    .exec()
    .then(function (results) {
      results.forEach(function (row) {
        var customerCategory = {};
        customerCategory.idAtClient = row["idAtClient"];
        customerCategory.idAtServer = row["idAtServer"];
        customerCategory.deleted = row["deleted"];

        tableDef = dataModels["Kundenkategorie"];
        tableDef.columns.forEach(function (column) {
          customerCategory[column.nameScope] = row[column.nameLocal];
        });

        if (!customerCategory.deleted) {
          $scope.customerCategories.push(customerCategory);
        }
      });

      if (functionOnSuccess != null)
        functionOnSuccess($scope.customerCategories);
    });
}

// loads a customer from the local db
function loadCustomer($rootScope, $scope, idAtClient, functionOnSuccess) {
  log("load customer with id " + idAtClient);
  if (isNaN(idAtClient)) idAtClient = -1;

  var tableCustomers = $rootScope.db.getSchema().table(dataModels.Kunde.table);
  var tableDef = dataModels["Kunde"];

  $rootScope.db
    .select()
    .from(tableCustomers)
    .where(tableCustomers.idAtClient.eq(idAtClient))
    .exec()
    .then(function (results) {
      if (!results || results.length != 1) {
        $scope.customer = undefined;

        if (functionOnSuccess) functionOnSuccess(undefined);
      } else {
        row = results[0];
        var customerEdit = {};
        customerEdit.idAtClient = row["idAtClient"];
        customerEdit.idAtServer = row["idAtServer"];
        customerEdit.deleted = row["deleted"];

        log("row customer: ", row);

        tableDef.columns.forEach(function (column) {
          customerEdit[column.nameScope] = row[column.nameLocal];
        });

        if (!customerEdit.remarks) customerEdit.remarks = "";
        if (!customerEdit.keywords) customerEdit.keywords = "";
        if (!customerEdit.contactFrequencyDays)
          customerEdit.contactFrequencyDays = 0;
        /*
				customerEdit.htmlAngebot = row['htmlAngebot'];
				customerEdit.htmlAuftrag = row['htmlAuftrag'];
				customerEdit.htmlKundenkontakt = row['htmlKundenkontakt'];
				customerEdit.htmlTermin = row['htmlTermin'];
				*/

        log(
          "   found a customer " +
            customerEdit.firstName +
            " " +
            customerEdit.lastName
        );

        $scope.customer = customerEdit;
        $scope.$apply();

        if (functionOnSuccess) functionOnSuccess($scope.customer);
      }
    });
}

// load offers resp. orders of a customer from local db; $scope.nameModel must be set
function loadSales(idCustomerAtClient, $rootScope, $scope, functionOnSuccess) {
  var tableSales = $rootScope.db
    .getSchema()
    .table(dataModels[$scope.nameModel].table);
  log("loading sales of " + $scope.idCustomerAtClient);
  var tableDef = dataModels[$scope.nameModel];

  $scope.sales = [];

  $rootScope.db
    .select()
    .from(tableSales)
    .where(tableSales.idCustomerAtClient.eq(idCustomerAtClient))
    .orderBy(tableSales.Datum_6, lf.Order.ASC)
    .exec()
    .then(function (results) {
      results.forEach(function (row) {
        var sale = {};
        sale.idAtClient = row["idAtClient"];
        sale.idAtServer = row["idAtServer"];
        sale.deleted = row["deleted"];

        sale.idCustomerAtClient = row["idCustomerAtClient"];

        tableDef.columns.forEach(function (column) {
          sale[column.nameScope] = row[column.nameLocal];
        });

        setPrices(sale, $rootScope.currency);

        var dateDummy = new Date(sale.date);
        sale.textDate = formatDate(sale.date);
        log("sale number: " + sale.number);

        if (!sale.deleted) {
          log("found a sale: ", sale);
          setPrices(sale, $rootScope.currency);
          $scope.sales.push(sale);
          log("sales size " + $scope.sales.length);
          $scope.$apply();
        }
      });

      if (functionOnSuccess != null) functionOnSuccess($scope.sales);
    });
}

// load sale locally; $scope.nameModel and $scope.nameModelPosition must be set
function loadSale($rootScope, $scope, idSale, functionOnSuccess) {
  log("load single sale: " + idSale);

  var tableSales = $rootScope.db
    .getSchema()
    .table(dataModels[$scope.nameModel].table);
  var tableDef = dataModels[$scope.nameModel];

  try {
    $rootScope.db
      .select()
      .from(tableSales)
      .where(tableSales.idAtClient.eq(idSale))
      .exec()
      .then(function (results) {
        log("load single sale: after query ", results);
        results.forEach(function (row) {
          var saleEdit = {};
          saleEdit.idAtClient = row["idAtClient"];
          saleEdit.idAtServer = row["idAtServer"];
          saleEdit.deleted = row["deleted"];

          saleEdit.idCustomerAtClient = row["idCustomerAtClient"];

          tableDef.columns.forEach(function (column) {
            saleEdit[column.nameScope] = row[column.nameLocal];
          });

          var dateDummy = new Date(saleEdit.date);
          saleEdit.textDate = formatDate(saleEdit.date);

          setPrices(saleEdit, $rootScope.currency);

          log("   found a sale ", saleEdit);
          log("typeof date: " + typeof saleEdit.date);

          $scope.sale = saleEdit;

          $scope.sale.sumNetBeforeDiscountV = 0;
          loadSalePositions($rootScope, $scope, $scope.sale, function () {
            for (
              var iSalePos = 0;
              iSalePos < $scope.sale.positions.length;
              iSalePos++
            ) {
              var position = $scope.sale.positions[iSalePos];
              $scope.sale.sumNetBeforeDiscountV += position.priceSumNetV;
            }
            $scope.sale.sumNetDiscountV =
              saleEdit.sumNetBeforeDiscountV - saleEdit.sumNetV;
            setPrices($scope.sale, $rootScope.currency);

            // sort positions by sequence of idAtClient
            if (
              $scope.sale.positions.length > 0 &&
              $scope.sale.sequenceOfPositionIdsAtClient
            ) {
              var arrSequPosIdsAtClient =
                $scope.sale.sequenceOfPositionIdsAtClient.split(",");
              $scope.sale.positions.sort(function (pos0, pos1) {
                var iPos0 = arrSequPosIdsAtClient.indexOf("" + pos0.idAtClient);
                var iPos1 = arrSequPosIdsAtClient.indexOf("" + pos1.idAtClient);
                log(JSON.stringify(arrSequPosIdsAtClient));
                log(
                  "comparing " +
                    pos0.idAtClient +
                    " and " +
                    pos1.idAtClient +
                    " ( " +
                    iPos0 +
                    "/" +
                    iPos1 +
                    ") in " +
                    $scope.sale.sequenceOfPositionIdsAtClient
                );
                return iPos0 - iPos1;
              });
            }

            $scope.$apply();

            if (functionOnSuccess) {
              functionOnSuccess($scope.sale);
            }
          });

          return;
        });
      });
  } catch (err) {
    log("caught err: ", err);
  }
}

// load all positions of a sale locally; $scope.nameModelPosition must be set
function loadSalePositions($rootScope, $scope, sale, functionOnSuccess) {
  loadSalePositionsByIdSaleAtClient(
    $rootScope,
    $scope,
    sale.idAtClient,
    function (positions) {
      sale.positions = positions;
      if (functionOnSuccess) functionOnSuccess(sale);
    }
  );
}

// load all positions of a sale locally; $scope.nameModelPosition must be set
function loadSalePositionsByIdSaleAtClient(
  $rootScope,
  $scope,
  idSaleAtClient,
  functionOnSuccess
) {
  log("$scope.nameTablePosition: " + $scope.nameModelPosition);
  var tableSalePositions = (tableSalePositions = $rootScope.db
    .getSchema()
    .table(dataModels[$scope.nameModelPosition].table));
  var tableDef = dataModels[$scope.nameModelPosition];

  var positions = [];

  $rootScope.db
    .select()
    .from(tableSalePositions)
    .where(tableSalePositions.idParentAtClient_9999.eq(idSaleAtClient))
    .exec()
    .then(function (results) {
      results.forEach(function (row) {
        var salePosEdit = {};
        salePosEdit.idAtClient = row["idAtClient"];
        salePosEdit.idAtServer = row["idAtServer"];
        salePosEdit.deleted = row["deleted"];

        salePosEdit.idParentAtClient = row["idParentAtClient"];
        salePosEdit.idArticleAtClient = row["idArticleAtClient"];
        tableDef.columns.forEach(function (column) {
          salePosEdit[column.nameScope] = row[column.nameLocal];

          /*if(column.nameLocal[column.nameLocal.length - 1] == 'V'){
						salePosEdit[column.nameScope.substring(0, column.nameScope.length - 1)] =
							convertValueToPrice(salePosEdit[column.nameScope], $rootScope.currency);
					}*/
        });

        salePosEdit.taxFactor =
          Math.round(salePosEdit.taxFactor * 1000.0) / 1000.0;

        setPrices(salePosEdit, $rootScope.currency);

        log("   idArticleAtClient: " + salePosEdit.idArticleAtClient);

        salePosEdit.number = "";
        if (salePosEdit.idArticleAtClient) {
          var tableArticles = $rootScope.db
            .getSchema()
            .table(dataModels["Artikel"].table);
          $rootScope.db
            .select()
            .from(tableArticles)
            .where(tableArticles.idAtClient.eq(salePosEdit.idArticleAtClient))
            .exec()
            .then(function (results) {
              results.forEach(function (row) {
                log("article: ", row);
                salePosEdit.number = row["Artikelnr_5"];
                log("article number: " + row["Artikelnr_5"]);
                $scope.$apply();
              });
            });
        }

        if (!salePosEdit.deleted) {
          log("   found a salePosition ", salePosEdit);
          positions.push(salePosEdit);
        }
      });

      if (functionOnSuccess != null) functionOnSuccess(positions);
    });
}

// load a single sale position locally; $scope.nameModelPosition must be set
function loadSalePosition(
  $rootScope,
  $scope,
  idSalePositionAtClient,
  functionOnSuccess
) {
  log(
    "load single salePosition: " +
      idSalePositionAtClient +
      " of " +
      $scope.nameModelPosition
  );

  var tableSalePositions = $rootScope.db
    .getSchema()
    .table(dataModels[$scope.nameModelPosition].table);
  var tableDef = dataModels[$scope.nameModelPosition];

  $rootScope.db
    .select()
    .from(tableSalePositions)
    .where(tableSalePositions.idAtClient.eq(idSalePositionAtClient))
    .exec()
    .then(function (results) {
      log("found # rows: " + results.length);
      results.forEach(function (row) {
        log("sale position row (" + idSalePositionAtClient + "): ", row);
        var salePosEdit = {};
        salePosEdit.idAtClient = row["idAtClient"];
        salePosEdit.idAtServer = row["idAtServer"];
        salePosEdit.deleted = row["deleted"];

        salePosEdit.idParentAtClient = row["idParentAtClient"];
        salePosEdit.idArticleAtClient = row["idArticleAtClient"];

        tableDef.columns.forEach(function (column) {
          salePosEdit[column.nameScope] = row[column.nameLocal];
        });

        salePosEdit.textDescription = convertRtfToPlain(
          salePosEdit.description
        );
        salePosEdit.textDescription2 = convertRtfToPlain(
          salePosEdit.description2
        );

        setPrices(salePosEdit, $rootScope.currency);

        log("   found a salePosition ", salePosEdit);

        log("   idArticleAtClient: " + salePosEdit.idArticleAtClient);

        if (salePosEdit.idArticleAtClient) {
          var tableArticles = $rootScope.db
            .getSchema()
            .table(dataModels["Artikel"].table);
          $rootScope.db
            .select()
            .from(tableArticles)
            .where(tableArticles.idAtClient.eq(salePosEdit.idArticleAtClient))
            .exec()
            .then(function (results) {
              results.forEach(function (row) {
                log("article: ", row);
              });
            });
        }

        $scope.salePosition = salePosEdit;

        if (functionOnSuccess) functionOnSuccess($scope.salePosition);

        return;
      });
    });
}

function logStackTrace() {
  var e = new Error("dummy");
  var stack = e.stack
    .replace(/^[^\(]+?[\n$]/gm, "")
    .replace(/^\s+at\s+/gm, "")
    .replace(/^Object.<anonymous>\s*\(/gm, "{anonymous}()@")
    .split("\n");
  console.log(stack);
}

function buttonSyncReset($scope, ParametersStart) {
  ParametersStart.buttonSyncText = "Sync";
  ParametersStart.buttonSyncEnabled = true;
  ParametersStart.isSyncRunning = false;
  ParametersStart.buttonLoginLogoutEnabled = true;
  ParametersStart.buttonLoginLogoutVisible = true;
  ParametersStart.buttonStartStopProjectTimeEnabled = true;

  if (!$scope.$$phase) $scope.$apply();
}

function buttonSyncSet($scope, ParametersStart, text) {
  ParametersStart.buttonSyncText = text;
  ParametersStart.buttonSyncEnabled = false;
  ParametersStart.isSyncRunning = true;
  ParametersStart.buttonLoginLogoutEnabled = true; // false
  ParametersStart.buttonLoginLogoutVisible = true;
  ParametersStart.buttonStartStopProjectTimeEnabled = true; // false

  if (!$scope.$$phase) $scope.$apply();
}

function parametersStartResetLoggedIn($scope, ParametersStart, nameUser) {
  ParametersStart.buttonSyncText = "Sync";
  ParametersStart.buttonSyncEnabled = true;
  ParametersStart.buttonLoginLogoutText = "Logout";
  ParametersStart.buttonLoginLogoutEnabled = true;
  ParametersStart.buttonLoginLogoutVisible = true;
  ParametersStart.textNameUser = nameUser;

  if (!$scope.$$phase) $scope.$apply();
}

function stringifyCircular(object) {
  var cache = [];
  var text = JSON.stringify(object, function (key, value) {
    if (typeof value === "object" && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference found, discard key
        return;
      }
      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
  cache = null; // enable garbage collection

  return text;
}

var enableLog = true;
function enableLogging(eL) {
  enableLog = eL;
}

function log() {
  if (!enableLog) return;
  //console.trace();
  console.log.apply(null, arguments);
}

/*
function log(text, objectToStringify) {
  if (!enableLog) return;

  if (objectToStringify) console.log(text + JSON.stringify(objectToStringify));
  else console.log(text);
}*/

// check any response from server
/*
function checkResponse($rootScope, $scope, $location, response) {
  log("response: ", response);

  if (!response) {
    buttonSyncReset($scope, $scope.ParametersStart);
    $translate("START_SYNC_UNKNOWN_ERROR").then(function (textMsg) {
      $.bootstrapPurr(textMsg, {
        type: "danger",
        allowDismissType: "hover",
      });
    });
    return false;
  } else if (response.status != "OK") {
    buttonSyncReset($scope, $scope.ParametersStart);
    if (response.data === "INVALID_TOKEN") {
      writeNameUser($rootScope, "", function () {
        $scope.ParametersStart.textNameUser = "";
        log("Redirect");
        $location.path("/login");
        $scope.$apply();
      });
    } else {
      $translate("START_SYNC_UNKNOWN_ERROR").then(function (textMsg) {
        $.bootstrapPurr(textMsg + " " + JSON.stringify(response), {
          type: "danger",
          allowDismissType: "hover",
        });
      });
    }
    return false;
  } else return true;
}
*/

// rebuilds and saves sequence of positions based on the actual sale positions in object sale
function buildSequenceOfPositionIdsAtClientAndStore(
  $rootScope,
  nameModel,
  sale,
  callbackDone
) {
  // build sequence of ids
  var stringOfIds = "";
  for (var iPos = 0; iPos < sale.positions.length; iPos++) {
    if (stringOfIds) stringOfIds += ",";
    stringOfIds += sale.positions[iPos].idAtClient;
  }

  sale.sequenceOfPositionIdsAtClient = stringOfIds;

  // store locally
  var tableSale = $rootScope.db.getSchema().table(dataModels[nameModel].table);
  $rootScope.db
    .update(tableSale)
    .set(tableSale.seqPosIdsAtClient_9999, sale.sequenceOfPositionIdsAtClient)
    .where(tableSale.idAtClient.eq(sale.idAtClient))
    .exec()
    .then(function () {
      if (callbackDone) callbackDone();
    });

  // create and store manipulation operation
  var tableManipulations = $rootScope.db.getSchema().table("manipulations");

  // delete previous update operation of the same type; repetition of same manipulation is not necessary
  $rootScope.db
    .delete()
    .from(tableManipulations)
    .where(
      lf.op.and(
        tableManipulations.type.eq("update"),
        lf.op.and(
          tableManipulations.nameTable.eq(dataModels[nameModel].table),
          lf.op.and(
            tableManipulations.idAtClient.eq(sale.idAtClient),
            tableManipulations.nameColumn.eq("Positionen_82")
          )
        )
      )
    )
    .exec()
    .then(function () {});

  var rows = [];

  // the 'update sequence of positions' operation
  var row = tableManipulations.createRow({
    id: 0,
    type: "update",
    nameTable: dataModels[nameModel].table,
    idAtClient: sale.idAtClient,
    nameColumn: "Positionen_82",
  });
  rows.push(row);

  // store operation in table 'manipulations'
  $rootScope.db
    .insertOrReplace()
    .into(tableManipulations)
    .values(rows)
    .exec()
    .then(function (results) {});
}

// connection error with server occured
function onErrorNoConnection($scope, $translate) {
  buttonSyncReset($scope, $scope.ParametersStart);
  $translate("START_SYNC_CONNECTION_REFUSED").then(function (textMsg) {
    $.bootstrapPurr(textMsg, {
      type: "danger",
      allowDismissType: "hover",
    });
  });
}
